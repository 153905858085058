<template>
  <div class="py-32">

    <div class="flex justify-center items-center flex-col">

      <h2 class="text-3xl font-bold pb-12">Your Profile</h2>

      <div class="mt-16 profile_container">
      <div class="cloudinary-container">
        <cld-image cloudName="vitel-health" :publicId="publicId" v-if="publicId !== ''">
          <cld-transformation width="168" height="168" gravity="face" crop="thumb" />
          <cld-placeholder type="pixelate"></cld-placeholder>
          <cld-transformation radius="500" />
        </cld-image>
        <img
        v-else
        :src="$auth.user.picture"
        alt="User's profile picture"
        class="rounded-circle img-fluid profile-picture"
      >
      <div>
        <span class="upload-icon">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#48BFE3" stroke="white"/>
            <path d="M15.5 8.5C15.6313 8.36868 15.7872 8.26451 15.9588 8.19344C16.1304 8.12236 16.3143 8.08578 16.5 8.08578C16.6857 8.08578 16.8696 8.12236 17.0412 8.19344C17.2128 8.26451 17.3687 8.36868 17.5 8.5C17.6313 8.63132 17.7355 8.78722 17.8066 8.9588C17.8776 9.13038 17.9142 9.31428 17.9142 9.5C17.9142 9.68572 17.8776 9.86961 17.8066 10.0412C17.7355 10.2128 17.6313 10.3687 17.5 10.5L10.75 17.25L8 18L8.75 15.25L15.5 8.5Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <input
          type="file"
          name="upload"
          id="upload"
          class=""
          placeholder="Upload File"
          @change="profileUpload"
          ref="fileInput"
          enctype="multipart/form-data"
          accept=".jpg, .png"
        > 
      </div> 
      </div>
      <h4 class="text-center text-prime mt-3 font-bold text-xl">{{ $auth.user.name }}</h4>
      <div class="flex items-center">
        <p class="text-small mr-2">{{ $auth.user.email }}</p>
        <span v-if="$auth.user.email_verified !== true">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#F76B6B"/>
            <path d="M10 6L6 10" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L10 10" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span v-else>
          <img src="@/assets/check.svg" alt="" class="check-green">
        </span>
      </div>
      <button class="josefin_font upgrade_button font-semibold">Update Password</button>
    </div>

      <!-- <div class="relative mb-6">
        <img v-if="$auth.user.picture === ''" src="@/assets/img/avatar.svg" alt="avatar">
        <img v-else :src="$auth.user.picture" class="w-40 h-40 rounded-full" alt="avatar">
        <span class="absolute bottom-7 right-0 cursor-pointer">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#48BFE3" stroke="white"/>
            <path d="M15.5 8.49991C15.6313 8.36859 15.7872 8.26441 15.9588 8.19334C16.1304 8.12227 16.3143 8.08569 16.5 8.08569C16.6857 8.08569 16.8696 8.12227 17.0412 8.19334C17.2128 8.26441 17.3687 8.36859 17.5 8.49991C17.6313 8.63123 17.7355 8.78713 17.8066 8.95871C17.8776 9.13029 17.9142 9.31419 17.9142 9.49991C17.9142 9.68562 17.8776 9.86952 17.8066 10.0411C17.7355 10.2127 17.6313 10.3686 17.5 10.4999L10.75 17.2499L8 17.9999L8.75 15.2499L15.5 8.49991Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>

      <h3 class="font-semibold text-xl lato">{{ $auth.user.name }}</h3>

      <div class="flex items-center mb-6">
        <p class="text-maingrey font-normal lato pr-1.5">{{ $auth.user.email }}</p>
        <span class="cursor-pointer">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="16" height="16" rx="8" fill="#DF5959"/>
            <path d="M10 6.5L6 10.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6.5L10 10.5" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>

      <button class="bg-primary rounded-full py-3 px-6 text-white focus:outline-none w-52">Update Password</button> -->
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  beforeMount() {
    const response = this.$auth.user;
    if(response['https://custom_claims/role'] === 'Client') {
      return 
    } else {
      window.location.href = "https://access.vitelhealth.com";
    }
  },

  data() {
    return {
      imageFile: '',
      publicId: '',
      loader: false
    }
  },
   async created() {
    if(this.$auth.user !== undefined) {
     await this.fetchSecretKeys()
    }
  },
  methods: {
    ...mapActions([
    'fetchSecretKeys',
    'updateProfilePicture',
    'updatePassword',
  ]),
  async profileUpload(e) {
    this.loader = true;
    if(e.target.files) {
      const file = e.target.files[0];
      await this.handleUpload(file)
      this.loader = false;
    }
    },
     async handleUpload(file) {
      this.loader = true;
      const cloudinary = {
        apiKey: this.secretKeys.cloudinaryKey,
        cloudName: this.secretKeys.cloudinaryName,
        uploadPreset: this.secretKeys.cloudinaryUploadPreset
      }
      try {
        const clodinaryURL = `https://api.cloudinary.com/v1_1/${cloudinary.cloudName}/upload`
        const formData = new FormData()
        formData.append("file", file);
        formData.append("upload_preset", cloudinary.uploadPreset);
        const response = await fetch(clodinaryURL, {
          method: 'POST',
          body:  formData,
        })
        let commits = await response.json()
        this.loader = false;
        this.imageFile = commits.secure_url
        this.publicId = commits.public_id
        const payload = {
          user_id: this.$auth.user.sub,
          image_url: this.imageFile
        }
        await this.updateProfilePicture(payload)
        .then((response) => {
          if (response.statusCode === 200) {
            this.$notify.success({
              title: 'Success',
              message: 'Profile picture updated successfully',
              type: 'success',
           })
          }
        })
      } catch (e) {
        this.loader = false;
        this.$notify.error({
          title: 'Success',
          message: `${e.response.data}`,
          type: 'error',
        })
      }
    },
  },
  computed: {
    ...mapState(['secretKeys', 'profile']),
    validateConfirmPassword() {
      return this.updateForm.new_password === this.updateForm.confirm_password
    }
  },
}
</script>

<style scoped>
.text-small {
  color: #666666;
  font-weight: 400;
}
.profile-picture {
  width: 10.5rem;
  height: 10.5rem;
  border-radius: 50%;
}
.profile_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mt-16 {
  margin-top: 4rem;
}
.plan_container {
  margin: 1.7rem 0;
  padding: 0.2rem 1.5rem;
  background: #FFEDD1;
  border-radius: 3.125rem;
  color: #E4961F;
}
.check-green {
  width: 1rem;
  height: 1rem;
}
.upgrade_button {
  border: none;
  margin-top: 1.5rem;
  background: #48BFE3;
  border-radius: 8px;
  padding: 1rem;
  color: #ffffff;
  width: 100%;
}
.upgrade_button:active, .upgrade_button:focus {
  outline: none;
}

.cloudinary-container {
  position: relative;
}
#upload {
  position: absolute;
  opacity: 0;
  top: 6.7rem;
  right: 0rem;
  width: 2rem;
}

.upload-icon {
  position: absolute;
  right: 0;
  top: 7rem;
  cursor: pointer;
}

.update_membership {
  text-align: center;
  color: #DF5959;
  /*update color above to green*/
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.5rem;
  padding-top: 1.5rem;
}

/* Responsive Design */
@media (max-width: 575.98px) {
  .upgrade_button {
    width: 63%;
  }
}
</style>